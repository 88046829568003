import React from 'react';
import PropTypes from 'prop-types';
import { AvatarWrapper } from './styles';

function Avatar({ small, profilePhotoUrl }) {
  return (
    <AvatarWrapper
      small={small}
      bgImage={profilePhotoUrl}
    />
  );
}

Avatar.defaultProps = {
  profilePhotoUrl: '',
  small: false,
};

Avatar.propTypes = {
  small: PropTypes.bool,
  profilePhotoUrl: PropTypes.string,
};

export default Avatar;
